import React, { useState, Fragment, useRef } from "react";
import { createContext } from "react";
import { Toast } from "primereact/toast";
import "../assets/css/toast.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";    

const AlertContext = createContext();
const { Provider, Consumer } = AlertContext;

function AlertProvider({ children }) {
  const [summary, setSummary] = useState(null);
  const [detail, setDetail] = useState(null);
  const [severity, setSeverity] = useState(null);
  const toast = useRef(null);
  const toastTR = useRef(null);
  const toastTL = useRef(null);
  const toastBR = useRef(null);
  const toastBL = useRef(null);

  const mensajeAlerta = (summary, detail, severity) => {

    toast.current.show({
      severity,
      summary,
      detail,
      life: 3000,
    });
  };

  const mensajeAlerta2 = (summary, detail, severity) => {
    toast.current.show({
      severity,
      summary,
      detail,
      sticky: true,
    });
  };

  const mensajeAlertaTR = (summary, message, severity) => {
    toastTR.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  };


  const mensajeAlertaBR = (summary, message, severity) => {
    toastBR.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  };

  const mensajeAlertaBL = (summary, message, severity) => {
    toastBL.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  };


  const mensajeAlertaTL = (summary, message, severity) => {
    toastTL.current.show({
      severity: severity,
      summary: summary,
      detail: message,
      life: 5000,
    });
  };

  const cerrarAlerta = () => {
    toast.current.clear();
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <Toast ref={toastTR} />
      <Toast ref={toastTL} position="top-left"></Toast>
      <Toast ref={toastBR} position="bottom-right"></Toast>
      <Toast ref={toastBL} position="bottom-left"></Toast>
      <Provider
        value={{
          mensajeAlerta,
          mensajeAlerta2,
          mensajeAlertaTR,
          mensajeAlertaTL,
          mensajeAlertaBR,
          mensajeAlertaBL,
          cerrarAlerta,
        }}
      >
        {children}
      </Provider>
    </Fragment>
  );
}

export { AlertProvider, Consumer as AlertConsumer, AlertContext };
